body {
  color: #5e6e82;
  text-align: left;
  background-color: #edf2f9;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #edf2f9;
  border-radius: 0.375rem;
  -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%),
    0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.bg-light {
  background-color: #f9fafd !important;
}
.bg-200 {
  background-color: #edf2f9 !important;
}
.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid #edf2f9;
  font-weight: 600;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.dropdown-menu {
  font-size: 0.83333rem;
  overflow: hidden;
  border: 0px;
  border-radius: 0.375rem;
  -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%),
    0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #edf2f9;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #344050;
  text-decoration: none;
  background-color: #edf2f9;
}

.modal-open {
  overflow: hidden;
}

.bg-gradient-primary {
  background: #6088b3 !important;
  background: linear-gradient(45deg, #6088b3 0%, #295c93 100%) !important;
  border-color: #6088b3 !important;;
}
.bg-gradient-info {
  background: #0dcaf0 !important;
  background: linear-gradient(45deg, #00d4ff 0%, #0dcaf0 100%) !important;
  border-color: #0dcaf0 !important;
}
.bg-gradient-warning {
  background: #ffc107 !important;
  background: linear-gradient(45deg, #f9b115 0%, #ffc107 100%) !important;
  border-color: #ffc107 !important;
}
.bg-gradient-danger {
  background: #dc3545 !important;
  background: linear-gradient(45deg, #e55353 0%, #dc3545 100%) !important;
  border-color: #dc3545 !important;
}
.progress-xs {
  height: 4px;
}
.font-size-lg {
  font-size: 1.08rem;
}
.font-size-sm {
  font-size: 0.8rem;
}
.text-dark-75 {
  color: #3f4254 !important;
}
.text-dark-25 {
  color: #7d7d7d !important;
}
.text-link {
  color: #007af7;
  text-decoration: underline;
}
.text-value-lg {
  font-size: 1.3rem;
}
.font-weight-bolder {
  font-weight: 600 !important;
}
.icon-xl {
  font-size: 1.75rem !important;
}
.cursor-pointer {
  cursor: pointer;
}

.bg-no-color {
  background-color: #ffffff;
  opacity: 0.8;
  background-image: repeating-linear-gradient(45deg, #b3b3b3 25%, transparent 25%, transparent 75%, #b3b3b3 75%, #b3b3b3 ), repeating-linear-gradient(45deg, #b3b3b3 25%, #ffffff 25%, #e5e5f7 75%, #b3b3b3 75%, #b3b3b3 );
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
}

.bg-no-color-red {
  background-color: #ffffff;
  opacity: 0.8;
  background-image: repeating-linear-gradient(45deg, #f74444 25%, transparent 25%, transparent 75%, #f74444 75%, #f74444), repeating-linear-gradient(45deg, #f74444 25%, #ffffff 25%, #e5e5f7 75%, #f74444 75%, #f74444);
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
}

.ani-fadeIn2s {
  animation: 2s aniFadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.ani-fadeIn3s {
  animation: 3s aniFadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

/* Control */
.mtfc-select2 [class$="-placeholder"] {
  color: var(--color-placeholder);
}

.ani-run {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.ani-run.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.ani-run.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s
}

.ani-run.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s
}

.ani-run.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s
}

.ani-run.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s
}

.ani-run.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s
}

.ani-run.fast {
  -webkit-animation-duration: .8s;
  animation-duration: .8s
}

.ani-run.faster {
  -webkit-animation-duration: .5s;
  animation-duration: .5s
}

.ani-run.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s
}

.ani-run.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s
}

@-webkit-keyframes ani_heartBeat {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3)
  }

  28% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3)
  }

  70% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes ani_heartBeat {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3)
  }

  28% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3)
  }

  70% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

.ani-heartBeat {
  -webkit-animation-name: ani_heartBeat;
  animation-name: ani_heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

@-webkit-keyframes ani_shake {
  0%,to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translate3d(-10px,0,0);
      transform: translate3d(-10px,0,0)
  }

  20%,40%,60%,80% {
      -webkit-transform: translate3d(10px,0,0);
      transform: translate3d(10px,0,0)
  }
}

@keyframes ani_shake {
  0%,to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translate3d(-10px,0,0);
      transform: translate3d(-10px,0,0)
  }

  20%,40%,60%,80% {
      -webkit-transform: translate3d(10px,0,0);
      transform: translate3d(10px,0,0)
  }
}

.ani-shake {
  -webkit-animation-name: ani_shake;
  animation-name: ani_shake;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes ani-bg { 
  0% { background-color:#fff; opacity: 0;}
  50% { background-color:#fbfbfb; opacity: 0.5;}
  100% { background-color:#fff;opacity: 0.1; } 

} 
@keyframes ani-bg { 
  0% { background-color:#fff; opacity: 0;}
  50% { background-color:#fbfbfb; opacity: 0.5;}
  100% { background-color:#fff; opacity: 0.1;} 
}

.ani-bg {
  -webkit-animation-name: ani-bg;
  animation-name: ani-bg;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes ani-bg2-start {
  0% {
    background: linear-gradient( 100deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0) 70% ) #f1f1f100;
    background-size: 200% 100%;
    background-position-x: 180%;
  }
  100% {
    background: linear-gradient( 100deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0) 70% ) #f1f1f177;
    background-size: 200% 100%;
    background-position-x: 180%;
  }
}
@keyframes ani-bg2 {
  from {
    background: linear-gradient( 100deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0) 70% ) #f1f1f177;    
    background-size: 200% 100%;
    background-position-x: 180%;
  }
  to {
    background: linear-gradient( 100deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0) 70% ) #f1f1f177;
    background-size: 200% 100%;
    background-position-x: -20%;
  }
}
@keyframes ani-bg2-pos {
  from {
    background-size: 200% 100%;
    background-position-x: 180%;
  }
  to {
    background-size: 200% 100%;
    background-position-x: -20%;
  }
}

@keyframes ani-svg-dash0 {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes ani-svg-dash100 {
  to {
    stroke-dashoffset: 100;
  }
}
svg .ani-dash0-100 {
  stroke-dashoffset: 0;
  animation: ani-svg-dash100 5s infinite linear;
}
svg .ani-dash100-0 {
  stroke-dashoffset: 100;
  animation: ani-svg-dash0 5s infinite linear;
}

/* .ani-loading-bg2 {
  animation: 2s ani-bg2-start linear 0.5s 1 normal forwards, 1s ani-bg2 2.5s linear infinite normal none;
} */
.ani-loading-bg2 {
  animation: 2s ani-bg2-start linear 0.2s 1 normal forwards, 1s ani-bg2-pos 0.2s linear infinite normal none;
}

/* chuyen trang thai trong 0.5s */
/* .ag-row-overlay{
  background: red;
  transition: background 2s 0s;  
}
.ag-row-overlay:hover{
  background-color: orange;  
} */

/* Chinh css disabled select2 mau cho giong form-control */
/* .css-1fhf3k1-control{
  background-color: #e9ecef!important;
  border-color: #ced4da!important;
}
.css-1fhf3k1-control>div>.css-1wa3eu0-placeholder{
  color: #495057!important;
}
.css-yk16xz-control>div>.css-1wa3eu0-placeholder{
  color: #d1d1d1!important;
} */

.mfc-select2 > div > [class*="-control"] > [class*="-ValueContainer"] > [class*="-placeholder"]{
  color: #d1d1d1!important;
}

.mfc-select2.mfc-disabled > div > [class*="-control"] {
  background-color: #e9ecef!important;
  border-color: #ced4da!important;
}
.mfc-select2.mfc-disabled > div > [class*="-control"] > div > [class*="-singleValue"] {
  color: #495057!important;
}

/* editor */
.ml-drawf .public-DraftStyleDefault-block{
  margin: 0.25rem 0;
}

a.btn{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

input[disabled], textarea[disabled] {
  color: inherit;
  opacity: 0.8;
  /* set opacity vi tren mobile mac dinh opacity rat nho */
}


.ag-hover-op9:hover {
  opacity: 0.9;
}
.ag-hover-op8:hover {
  opacity: 0.8;
}
.ag-hover-op7:hover {
  opacity: 0.7;
}
.ag-hover-op6:hover {
  opacity: 0.6;
}
.ag-hover-op5:hover {
  opacity: 0.5;
}
.ag-hover-dark10:hover {
  filter: brightness(90%);
}
.ag-hover-dark20:hover {
  filter: brightness(80%);
}
.ag-hover-light10:hover {
  filter: brightness(110%);
}
.ag-hover-light20:hover {
  filter: brightness(120%);
}

@keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}
span.ag-skeleton {
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.flatpickr-monthSelect-months{
  user-select: none;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month{
  display: flex;
}